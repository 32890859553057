import React from "react"
import kommissionierungHeader from "../../../assets/images/products/netstorsys/kommissionierung_header.svg"
import NetstorsysSidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import Outlook from "../Outlook"

const Kommissionierung = () => {

  return (
    <>
      <section className="services-details-area pt-100" style={{ marginBottom: "3em" }}>
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={kommissionierungHeader} alt="Kommissionierung" />
              </div>

              <div className="">
                <span className="products-sub-title">In einer Tour effizient</span>
                <h1 className={"pt-3"}>Kommissionieren mit NETSTORSYS</h1>
                <p>
                  Steigende Bestellzahlen und komplexere Lagerlogistik bringen manuelle Lagerverwaltung ans Limit. Unser
                  Lagerverwaltungssystem NETSTORSYS bietet höchste System-Kompatibilität, intuitive Nutzerführung und
                  maßgeschneiderte Features, um <b>schneller, zuverlässiger, einfacher und transparenter zu
                  kommissionieren</b>.
                </p>
                <h2>Strategisch lagern, zügig picken</h2>
                <p>
                  Herzstück unseres LVS sind fortschrittliche, flexible Lager- und Reservierungsstrategien für
                  optimierte Laufwege, die zweifelsfreie Identifikation und direkte Systemerfassung von Artikeln per
                  einfacher Barcode-Scans sowie die mehrsprachige Nutzerführung und Navigation durchs Lager.
                </p>
                <h2>Der Routenplaner fürs Lager</h2>
                <p>
                  Das Batchsystem fasst Single-Liner oder mehrere Bestellungen zu optimalen Multi-Order-Pickings
                  zusammen. Kommissioniertouren werden in Echtzeit um zusätzlich georderte Artikel erweitert, die “auf
                  dem Weg” liegen. Das LVS unterstützt dynamische Lagerhaltung und platziert Artikel bestmöglich für
                  besonders kurze und wenige Laufwege.
                </p>
                <h2>Waren schneller zur Hand</h2>
                <p>
                  Lagerteams sind zügiger eingearbeitet, picken effizienter, fehlerfrei und ohne Stress, weil die
                  Software assistiert und der fehleranfällige Datenübertrag entfällt. Das System aktualisiert laufend
                  die Bestandsdaten, stellt sie Warenwirtschaftssystemen bereit und ermöglicht so, Produktionen perfekt
                  zu planen und Kunden über Lieferbarkeit und Lieferstatus auf dem Laufenden zu halten. Die
                  Kommissionierung ist innerhalb weniger Minuten erledigt und verbucht.
                </p>
                <h2>Fein abgestimmte Arbeitsschritte</h2>
                <p>
                  Smarte Arbeitsteilung und klare Worksflows sind entscheidend für effiziente Kommissionierung. Soll der
                  Lagerplatz vor dem Pick bestätigt werden? Darf der Lagerist nicht vorgefundene Ware ausbuchen, offene
                  Aufträge sehen, bei Bedarf den Laufweg verlassen und Positionen priorisieren? Mit dem Rechte- und
                  Rollenmanagement werden Oberflächen und Abläufe individuell für jeden Mitarbeiter konfiguriert.
                </p>

                <Outlook
                  text={"Einfach smarter kommissionieren und mit NETSTORSYS Zeit, Kosten und Personal sparen."} />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <NetstorsysSidebar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Kommissionierung
